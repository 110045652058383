import React, { FC } from "react";
import {Box, Typography} from "@mui/material";
import './Showroom.css';
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

const VipArea: FC<any> = (): JSX.Element => {

    return (
        <Box className="showroom">
            <Box sx={{
                height: '100vh',
                overflow: 'hidden',
                position: 'relative',
            }} className="pageHero" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/VIP_Hero_v2-1920x920.jpg)`}}>

                <Navbar class='header' />

                <Typography variant="h1" className="pageHero__title title title-1" sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        fontSize: '120px',
                        lineHeight: '128px',
                    },
                    [theme.breakpoints.down("md")]: {
                        fontSize: '48px',
                        lineHeight: '50px',
                    },
                })}>
                    Discover our<br />
                    VIP Area
                </Typography>

                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                   
                </Box>

            </Box>

            <Box 
                className='inner' 
                sx={{
                    display: 'flex',
                    position: 'relative',
                    overflow: 'hidden',
                    paddingBottom: '150px',
                    flexWrap: 'wrap',
                }}
            >
                    <Box className="tiTwoCols__wrapper">
                        <Box className="tiTwoCols__col1">
                            <img src={`${process.env.PUBLIC_URL}/images/BET_4592_vip-01.jpg`} alt="" width={'100%'} />
                        </Box>
                        <Box className="tiTwoCols__col1" sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                width: '30%'
                            },
                            [theme.breakpoints.down("md")]: {
                                width: '100%',
                                mt: 5
                            },
                        })}>
                            <Typography variant="h5">
                                At the heart<br />
                                of Opus building
                            </Typography>
                            <p className="tiTwoCols__text">
                            Our exclusive VIP Area exudes watchmaking luxury, housing an impressive lounge area where you can discover an unrivalled selection of extremely rare timepieces with the utmost secrecy.
                            </p>
                        </Box>
                        <Box className="tiTwoCols__col1" sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                width: '30%'
                            },
                            [theme.breakpoints.down("md")]: {
                                width: '100%'
                            },
                        })}>
                            <p className="tiTwoCols__text">Located on the second floor, next to our showroom, our new VIP section is hosting private lounge area to offer exclusive experience and immerse you in the world of luxury Swiss watchmaking. There’s a definite contemporary art edge about this space with its tailor-made furniture.</p>
                        </Box>
                        <Box className="tiTwoCols__col1" sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                marginTop: '-230px', textAlign: 'right'
                            },
                            [theme.breakpoints.down("md")]: {
                                marginTop: 0,
                                width: '100%',
                                margin: '0 auto',
                            },
                        })}>
                            <img src={`${process.env.PUBLIC_URL}/images/BET_4592_vip-02-355x592.jpg`} alt="" width={'100%'} />
                        </Box>
                    </Box>
            </Box>


            <Box sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    display: 'flex',
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100vh',
                    alignItems: 'center',
                },
                [theme.breakpoints.down("md")]: {
                    display: 'block'
                },
            })}>
                <Box 
                    className="pageHero" 
                    style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/vip_billard.jpg)` }}
                    sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            width: '50vw',
                            height: '100vh',
                        },
                        [theme.breakpoints.down("md")]: {
                            width: '100vw',
                            height: '80vh',
                        },
                    })}
                >
                    
                </Box>
                <Box sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        width: '50%', marginTop: '55px'
                    },
                    [theme.breakpoints.down("md")]: {
                        width: '100%', marginTop: 0
                    },
                })}>
                    <Box sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            width: '50%',
                            ml: 10
                        },
                        [theme.breakpoints.down("md")]: {
                            width: '100%',
                            p: 2,
                            pt: 10,
                            pb: 10
                        },
                    })}>
                        <Typography variant="h3" sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                fontSize: '52px',
                                lineHeight: '66px',
                            },
                            [theme.breakpoints.down("md")]: {
                                fontSize: '32px',
                                lineHeight: '46px',
                                width: '100%'
                            },
                        })}>
                            We will cater to any request.
                        </Typography>
                        <p className="textImage__text-text">
                            Our VIP area is all about the environment you want to view your timepieces in. If you want to find out more about the services and offering available at Royal Boutique, don't hesitate to get in touch.
                        </p>
                    </Box>
                </Box>
            </Box>


            <Box className="contactPush" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/BET_5020_push-bas-page_blacked30-1920x920.jpg)`}}>
                <Typography variant="h1" className="contactPush__title title title-1" sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        fontSize: '120px',
                        lineHeight: '128px',
                    },
                    [theme.breakpoints.down("md")]: {
                        fontSize: '48px',
                        lineHeight: '50px',
                    },
                })}>
                    Contact us<br />
                    for further details
                </Typography>
            </Box>


            <Footer />
            
        </Box>
    );
};

export default VipArea;