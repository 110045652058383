import { Box } from "@mui/material";
import { useEffect } from "react";
import { ReactComponent as ClockIcon } from "../../images/clock.svg";
import "./Clock.css";

type Props = {
  onRightClick: () => void,
  onLeftClick: () => void,
  className: string,
  timer?: number,
};

const Clock = (props: Props) => {

  const { onRightClick, onLeftClick, className, timer } = props;
  
  useEffect(() => {

      function getRotate(element: any) {
          const rotate = element.style.transform.match(/rotate\((.+)\)/);
          return rotate && rotate[1].replace('deg', '');
      }

      const svgClockButtons = document.querySelectorAll('.' + className + ' .sliderDot__dot');
      for (var i=0; i < svgClockButtons.length; i++) {
          const item = svgClockButtons[i] as HTMLElement;
          item.onclick = function(){
              
              const children = document.querySelectorAll('.' + className + ' .sliderDot__dot');
              const activeItem = document.querySelectorAll('.' + className + ' .active')[0];
              const index = Array.prototype.slice.call(children).indexOf(item);
              const activeIndex = Array.prototype.slice.call(children).indexOf(activeItem);
              
              let shift = Math.abs(index - activeIndex) * 30;
              if(index < activeIndex){
                  shift *= -1;
              }
              
              const actives = document.querySelectorAll('.' + className + " .sliderDot__dot.active");
              actives.forEach(active => {
                  active.classList.remove("active");
              });

              item.classList.add('active');
              const svgClock = item.closest('.sliderDot__SVG');
              svgClock?.setAttribute('style', `transform: rotate(${Number(getRotate(svgClock)) - shift}deg);`);

              if(index < activeIndex){
                  onRightClick();
              }else{
                  onLeftClick();
              }
          }
      };

      // if(timer){
      //   setInterval(() => {
      //     const children = document.querySelectorAll('.' + className + ' .sliderDot__dot');
      //     const activeItem = document.querySelectorAll('.' + className + ' .active')[0];
      //     const activeIndex = Array.prototype.slice.call(children).indexOf(activeItem);

      //     if(children.length > activeIndex){
      //       activeItem.nextElementSibling?.dispatchEvent(new Event('click'));
      //     }if((children.length - 1) === activeIndex){
      //       children[0].dispatchEvent(new Event('click'));
      //     }

      //   }, timer * 1000)
      // }

  }, []);


  return (
    <Box className={className} component="div">
        <ClockIcon />
    </Box>
  );
};

export default Clock;
