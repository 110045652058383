import { createContext } from 'react';
import {useState} from 'react';

export type LoaderContextValue = {
  load: boolean,
  setLoad: Function
}

export const LoaderContext = createContext<LoaderContextValue>({
  load: false,
  setLoad: () => {}
});

export const LoaderProvider = ({children}: {children: any}) => {

    const [load, setLoad] = useState(false);

    return (
        <LoaderContext.Provider value={{
          load, 
          setLoad
        }}>
          {children}
        </LoaderContext.Provider>
    )
}