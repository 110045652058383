import React, { FC, useEffect, useState } from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, IconButton, List, ListItem, Typography} from "@mui/material";
import './Watches.css';
import { Navbar } from "../components/Navbar";
import { SlideItem } from "../components/SlideItem";
import { useNavigate, useParams } from "react-router-dom";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import { useApiClient } from "../context/ClientProvider";

export const tags = [
    'Chronograph',
    'Classic',
    'Manual Winding',
    'Self Winding',
    'Skeleton',
    'Tourbillon',
];

export const movements = [
    'Automatic',
    'Automatic chrono',
    'Automatic declutchable rotor',
    'Mechanical',
    'Quartz',
]

const UPLOAD_URL = process.env.REACT_APP_API + "uploads";

const Watches: FC<any> = (): JSX.Element => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [ isGridView, setIsGridView] = useState(false);
    const [ products, setProducts ]  = useState<any[]>([]);
    const [tag, setTag] = useState("");
    const [movement, setMovement] = useState("");
    const [brand, setBrand] = useState(id || "");
    const [brands, setBrands] = useState([]);
    const apiClient = useApiClient();

    const navigateToDetails = (id: number) => {
        setTimeout(() => {
            navigate(`/watch/${id}`);
        }, 0)
    }

    useEffect(() => {
        
        const getBrands = async () => {
            const brands = await apiClient.get(`api/categories?limit=10&size=0`);
            setBrands(brands.data.data || []);
        };

        getBrands();
    }, []);

    useEffect(() => {
        
        const getProducts = async () => {
            const api = await apiClient.get(`api/watches?limit=20&size=0&tag=${tag}&movement=${movement}&brand=${brand}`);
            setProducts(api.data?.data);
        };

        getProducts();
    }, [tag, movement, brand]);

    useEffect(() => {
        setBrand(id || "");
        setMovement("");
        setTag("");
    }, [id]);

    return (
        <Box className="watches">
            <Navbar dark darkLogo class="header" /> 

            <Box className="inner">
                <Box sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        display: 'flex', 
                        pt: 15,
                    },
                    [theme.breakpoints.down("md")]: {
                        display: 'block',
                        width: '100%',
                        pt: 15,
                    },
                })}>
                    <Box className="watchesList__sidebar" sx={(theme) => ({
                        [theme.breakpoints.down("md")]: {
                            width: '100%',
                            mb: 5,
                        },
                    })}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Typography variant="h4">Watches</Typography>

                            <Box sx={{display: { xs: 'none', lg: 'flex' }}}>
                                <IconButton type="button" onClick={() => setIsGridView(false)} style={{color: isGridView ? '#e2e2e2': 'black'}}>
                                    <CalendarViewWeekIcon />
                                </IconButton>
                                <IconButton type="button" onClick={() => setIsGridView(true)} style={{color: isGridView ? 'black': '#e2e2e2'}}>
                                    <ViewModuleIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <p className="watchesList__description">Royal Boutique offers the most exclusive and desirable watches on the market. We sell all our watches complete with box and paper, and you’ll also get 1-year warranty when you purchase your watch from us.</p>
                        
                        <List sx={{display: { xs: "none", md: "flex", flexDirection: 'column', width: '100%', color: '#000', fontSize: 14 }}}>
                            <ListItem onClick={() => setBrand("")} style={{color: brand === '' ? '#303030' : '#e2e2e2'}}>
                                <a href="javascript:void(0);" className="watchesList__filter-label">All</a>
                            </ListItem>
                            {brands.map(b => (
                                <ListItem onClick={() => setBrand(b['_id'])} style={{color: brand === b['_id'] ? '#303030' : '#e2e2e2'}}>
                                    <a href="javascript:void(0);" className="watchesList__filter-label">{b['title']}</a>
                                </ListItem>
                            ))}
                        </List>


                        <Accordion>
                            <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography>Categories</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List sx={{display: { xs: "none", md: "flex", flexDirection: 'column', width: '100%', color: '#000', fontSize: 14 }}}>
                                    
                                    {tags.map(t => (
                                        <ListItem onClick={() => setTag(t !== tag ? t : "")} style={{color: t === tag ? '#303030' : '#e2e2e2'}}>
                                            <a href="javascript:void(0);" className="watchesList__filter-label">{t}</a>
                                        </ListItem>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            >
                            <Typography>Movements</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List sx={{display: { xs: "none", md: "flex", flexDirection: 'column', width: '100%', color: '#000', fontSize: 14 }}}>
                                    {movements.map(m => (
                                        <ListItem onClick={() => setMovement(m !== movement ? m : "")} style={{color: movement === m ? '#303030' : '#e2e2e2'}}>
                                            <a href="javascript:void(0);" className="watchesList__filter-label">{m}</a>
                                        </ListItem>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    
                    </Box>

                    <Box className="watchesList__gallery" sx={(theme) => ({
                        [theme.breakpoints.down("md")]: {
                            width: '100%',
                            padding: 0
                        },
                    })}>
                        <Box sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                display: 'flex',flexWrap: 'wrap', margin: '0 -25px'
                            },
                            [theme.breakpoints.down("md")]: {
                                margin: 0
                            },
                        })}>
                            {products.map((item) => (
                                <SlideItem
                                    style={(theme) => ({
                                        [theme.breakpoints.up("md")]: {
                                            width: isGridView ? '33.33%' : '50%',
                                        },
                                        [theme.breakpoints.down("md")]: {
                                            width: '100%',
                                        },
                                    })}
                                    title={item.title}
                                    description={item.description}
                                    imgHeight={isGridView ? '300px' : '500px'}
                                    img={`${UPLOAD_URL}/${item.image}`} 
                                    onClick={() => navigateToDetails(item._id)}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
            
            
        </Box>
    );
};

export default Watches;