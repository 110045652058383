import { FC, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import './Service.css';


type Props = {
  image: string,
  text: string,
  title: string,
}

export const Service: FC<Props> = (props: Props): JSX.Element => {

  const { image, text, title } = props;

  useEffect(() => {
    
  }, [])

  return (
    <Box className="serviceShowcaseInfos" sx={(theme) => ({
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    })}>
        <Box>
          <Box sx={(theme) => ({
              display: "none",
              position: 'relative',
              width: '70px',
              height: '70px',
              [theme.breakpoints.up("md")]: {
                maxWidth: "350px",
                width: '100%', 
                textAlign: 'center',
                display: 'block',
              },
          })}>
              <svg className="serviceShowcaseInfos__icon-border icon icon-cog_empty">
                <use xlinkHref="#icon-cog_empty"></use>
              </svg>
              <Box  sx={(theme) => ({
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '50%',
                  height: '50%',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
              })}>
                <img src={image} alt={''} height={'100%'} />
              </Box>
          </Box>
        </Box>
        <Box style={{maxWidth: "500px", width: '100%'}} className={"serviceShowcase__col2"}>
            <Box sx={(theme) => ({
                display: "none",
                [theme.breakpoints.down("md")]: {
                  textAlign: 'center',
                  display: 'flex',
                  width: '70px',
                  height: '70px',
                  position: 'relative',
                  mt: 2,
                  mb: 2
                },
            })}>
                <svg className="serviceShowcaseInfos__icon-border icon icon-cog_empty">
                  <use xlinkHref="#icon-cog_empty"></use>
                </svg>
                <Box  sx={(theme) => ({
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    height: '50%',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                })}>
                  <img src={image} alt={''} height={'100%'} />
                </Box>
            </Box>

            <Box className="serviceShowcaseInfos__subtitle">{title}</Box>
            <Typography variant="h4">{title}</Typography>
            <p className="serviceShowcaseInfos__text">
                {text}
            </p>
            {/* <a href="#">Learn more</a> */}
        </Box>
    </Box>
  );
};

export default Service;