import {
  Box,
  List,
  Toolbar,
  ListItem,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import './Navbar.css';
import SearchIcon from '@mui/icons-material/Search';
import { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../context/LoaderProvider";
import { useNavigate } from 'react-router-dom';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import axios from "axios";

const baseURL = process.env.REACT_APP_API;
const axiosApi = axios.create({
    baseURL: baseURL,
});

interface Prop{
  class: string,
  dark?: boolean,
  darkLogo?: boolean,
  showMenu?: boolean,
  showSearch?: boolean,
}

const Navbar: React.FC<Prop> = (props: Prop): JSX.Element => {

  const { dark, darkLogo } = props;

  const {setLoad} = useContext(LoaderContext);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState<boolean>(props.showMenu || false);
  const [showSearch, setShowSearch] = useState<boolean>(props.showSearch || false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [darkTheme, setDarkTheme] = useState<boolean>(false);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
        
      const getBrands = async () => {
          const brands = await axiosApi.get(`api/categories?limit=4&size=0`);
          setBrands(brands.data.data || []);
      };

      getBrands();
  }, []);

  
  const goTo = (link: string) => {
    setLoad(true);

    setTimeout(() => {
      navigate(link);
    }, 1000);
  }

  useEffect(() => {
    setDarkTheme(showMenu || showSearch);
  }, [showMenu, showSearch]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        zIndex: 9,
        display: "inline-block",
      }}
      className={props.class}
    >

      <Box className="nav-container">
        <Toolbar disableGutters sx={{width: '100%'}}>
          <Box className={`mainMenu__brands ${showMenu ? 'is-active' : ''} `} component={'div'} onMouseLeave={() => setShowMenu(false)}>
            <Box className="inner">
              <Box className="mainMenu__brands-list">
                {brands.map(brand => (
                      <Box className="mainMenu__brands-item">
                          <a href={`/#/all-watches/${brand['_id']}`} className="mainMenu__brands-item-imageWrapper" title={brand['title']}>
                              <Box className="mainMenu__brands-item-image" style={{backgroundImage: `url(${process.env.REACT_APP_API}uploads/${brand['image']})`}}></Box>
                          </a>
                          <a href={`/#/all-watches/${brand['_id']}`} className="mainMenu__brands-item-link">{brand['title']}</a>
                      </Box>
                  ))}
              </Box>
              <Box className="mainMenu__brands-all">
                  <a href="javascript:void(0);" onClick={() => goTo("/all-watches")} className="mainMenu__brands-all-link">
                      All watches
                  </a>
              </Box>
            </Box>
          </Box>

          <Box className={`mainMenu__brands ${showSearch ? 'is-active' : ''} `} component={'div'} onMouseLeave={() => setShowSearch(false)}>
              <Box className="inner">
                  <Box sx={{p: 20}}>
                    <input type="text" className="search__input is-empty js-search-input" value="Search" />
                    <Box className="search__pannel-content">
                        <Box className="search__pannel-load js-search-load">
                            <Box className="loading-ring"></Box>
                        </Box>
                        <Box className="search__pannel-results js-search-result"></Box>
                    </Box>
                  </Box>
              </Box>
          </Box>
          <Box  sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              p: 2, display: { md: "flex", width: '100%', justifyContent: "space-between", position: 'relative' }, width: "100%"
            },
            [theme.breakpoints.down("md")]: {
              p: 1,
              display: 'flex',
              justifyContent: "space-between",
              alignItems: 'center',
              width: "100%"
            },
          })}>
            <Box sx={{flex: 1, zIndex: 9}}>
              <a href="javascript:void(0);" onClick={() => goTo("/")}>
                {(!darkLogo && !darkTheme) && <img src={`${process.env.PUBLIC_URL}/images/logo-white.svg`} alt="" />}
                {(darkLogo || darkTheme) && <img src={`${process.env.PUBLIC_URL}/images/logo-black.svg`} alt="" />}
              </a>
            </Box>

            <Box sx={{display: { xs: 'flex', md: "none"}, color: dark ? '#000' : '#FFF', p: 1}}>
              <IconButton type="button" sx={{ p: '10px' }} onClick={() => setShowMobileMenu(true)}>
                <DragHandleIcon style={{color: dark ? '#000' : '#FFF', transform: 'scale(1.8)'}} />
              </IconButton>
              <Box 
                className={`mainMenu__brands js-menu-brand ${showMobileMenu ? 'is-active' : ''} `}
                component={'div'}
                onMouseLeave={() => setShowMobileMenu(false)}
              >
                <Box className="inner">
                  <Box className="mainMenu__label">
                    Menu
                  </Box>
                  <List sx={{display: { md: "flex", justifyContent: "center", alignItems: 'center', width: '100%', color: 'inherit', fontSize: 32, lineHeight: '46px' }}}>
                    <ListItem onClick={() => goTo("/all-watches")}>
                      <a href="javascript:void(0);" className="nav-item-link" >Watches</a>
                    </ListItem>
                    <ListItem onClick={() => goTo("/our-showroom")}>
                      <a href="javascript:void(0);" className="nav-item-link">Our showroom</a>
                    </ListItem>
                    <ListItem onClick={() => goTo("/vip-area")}>
                      <a href="javascript:void(0);" className="nav-item-link">VIP Area</a>
                    </ListItem>
                    <ListItem>
                      <a href="#" className="nav-item-link">Services</a>
                    </ListItem>
                    <ListItem>
                      <a href="#" className="nav-item-link">Contact</a>
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Box>

            <Box sx={{display: { xs: 'none', md: "flex"}, flex: 1, color: (dark || darkTheme) ? '#000' : '#FFF'}} >
              <List sx={{display: { md: "flex", justifyContent: "center", alignItems: 'center', width: '100%', color: 'inherit', fontSize: 14 }}}>
                <ListItem onClick={() => goTo("/all-watches")} onMouseEnter={() => setShowMenu(true)}>
                  <a href="javascript:void(0);" className="nav-item-link" >Watches</a>
                </ListItem>
                <ListItem onClick={() => goTo("/our-showroom")}>
                  <a href="javascript:void(0);" className="nav-item-link">Our showroom</a>
                </ListItem>
                <ListItem onClick={() => goTo("/vip-area")}>
                  <a href="javascript:void(0);" className="nav-item-link">VIP Area</a>
                </ListItem>
                <ListItem>
                  <a href="#" className="nav-item-link">Services</a>
                </ListItem>
                <ListItem>
                  <a href="#" className="nav-item-link">Contact</a>
                </ListItem>
                <ListItem style={{marginLeft: 50}} onClick={() => setShowSearch(true)}>
                  <ListItemIcon sx={{minWidth: 0}}>
                    <SearchIcon style={{color: dark ? '#000' : '#FFF'}} />
                  </ListItemIcon>
                  <a href="javascript:void(0);" className="nav-item-link">Search</a>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Toolbar>
        
      </Box>
    </Box>
  );
};

export default Navbar;