import { Box, SxProps, Theme } from "@mui/material";
import "./SlideItem.css";

type Props = {
  img: string,
  style?: SxProps<Theme>,
  imgHeight?: string,
  width?: string,
  onClick?: () => void;
  title: string,
  description: string,
};

const SlideItem = (props: Props) => {

  const { img, onClick, style, imgHeight, width, title, description } = props;

  return (
    <Box className={"watch-slide-col"} sx={style} width={width}  component="div" onClick={onClick}>
        <a href="#" className="watchItem">
          <Box className="watchItem__image" style={{backgroundImage: `url(${img})`, height: imgHeight}}></Box>
          <Box className="watchItem__infos">
              <p className="watchItem__brand">{title}</p>
              <p className="watchItem__name">{description}</p>
          </Box>
        </a>
    </Box>
  );
};

export default SlideItem;
