import React, { FC, useContext, useEffect, useState } from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import './Home.css';
import { Navbar} from "../components/Navbar";
import { ReactComponent as Poylon } from "../images/polygon.svg";
import { Footer } from "../components/Footer";
import { Service } from "../components/Service";
import { Slider } from "../components/Slider";
import { Video } from "../components/Video";
import { Clock } from "../components/Clock";
import { ReactComponent as Cog } from "../images/cog.svg";
import { useApiClient } from "../context/ClientProvider";
import { LoaderContext } from "../context/LoaderProvider";
import { useNavigate } from "react-router-dom";

const videos = [
    `${process.env.PUBLIC_URL}/images/Royal-Boutique-short.mp4`,
    `${process.env.PUBLIC_URL}/images/RM-65-01-site-2-ok.mp4`,
  ];
  
const images = [
    `${process.env.PUBLIC_URL}/images/richard-mille_brand.jpg`,
    `${process.env.PUBLIC_URL}/images/patek-p.jpg`,
]

const Home: FC<any> = (): JSX.Element => {
    
    const [ videoUrl, setVideoUrl ]  = useState(videos[0]);
    const [ imageUrl, setImageUrl ]  = useState(images[0]);
    const [ soundOff, setSoundOff ]  = useState(true);
    const [ products, setProducts ]  = useState<any[]>([]);
    const [brands, setBrands] = useState([]);
    const apiClient = useApiClient();
    const {setLoad} = useContext(LoaderContext);
    const navigate = useNavigate();

    const goTo = (link: string) => {
        setLoad(true);

        setTimeout(() => {
            navigate(link);
        }, 1000);
    }

    useEffect(() => {
        
        const getBrands = async () => {
            const brands = await apiClient.get(`api/categories?limit=10&size=0`);
            setBrands(brands.data.data || []);
        };

        getBrands();
    }, []);

    useEffect(() => {
        
        const getProducts = async () => {
            const api = await apiClient.get(`api/watches?limit=20&size=0`);

            setProducts(api.data?.data);
        };

        getProducts();
    }, []);

    return (
        <Box>
            <Cog />
            <Box sx={{
                flexGrow: 1,
                height: "100vh",
                background: '#474538',
                position: 'relative',
                overflow: 'hidden'
            }}>

                <Navbar class="header" />

                {/* <Helper left={44} top={33} /> */}

                <Box className="video-background">
                    <Video className='videoSlide__video' url={videoUrl} soundOff={soundOff} />
                </Box>

                <Box className="video-layer"></Box>

                <Box sx={{
                    backgroundColor: 'inheret',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100%'
                }}>
                    <Box className="head-text">
                        <Typography variant="h1" sx={(theme) => ({
                                [theme.breakpoints.up("md")]: {
                                    fontSize: '120px',
                                    lineHeight: '128px',
                                },
                                [theme.breakpoints.down("md")]: {
                                    fontSize: '48px',
                                },
                            })}>
                            Where watch<br />
                            fanatics meet
                        </Typography>
                    </Box>
                </Box>
                
                <Box className="video-controls nav-container">
                    <Box sx={(theme) => ({
                        display: { xs: "none", md: "flex"}
                    })}>
                        <a href="#" className="scroll">
                            Scroll to discover
                        </a>
                    </Box>

                    <Box className="videoHero__controls-nav">
                        <Clock 
                            onRightClick={()=> setVideoUrl(videos[0])} 
                            onLeftClick={()=> setVideoUrl(videos[1])} 
                            className={'intro-clock'}
                        />
                    </Box>

                    <Box sx={(theme) => ({
                        display: { xs: "none", md: "flex"}
                    })}>
                        <button className={`sound-off ${soundOff ? '' : 'active'}`} onClick={() => setSoundOff(!soundOff)}>
                            <span className="videoSlide__toggleSound-off">Sound {soundOff ? 'off' : 'on'}</span>
                            <i className="videoSlide__toggleSound-icon"></i>   
                        </button>
                    </Box>
                    
                    
                </Box>

                
            </Box>

            <Box sx={{
                flexGrow: 1,
                background: '#FFF',
                position: 'relative',
                overflow: 'hidden',
                pt: 15,
                mb: 20,
            }}>
                <Box className="inner">
                    <Box sx={{mb: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Box sx={{display: 'flex', flexGrow: 1}}>
                            <Typography variant="h4">Latest Arrivals</Typography>
                            <Box>
                                <List sx={{display: { xs: "none", md: "flex", justifyContent: "center", alignItems: 'center', width: '100%', color: '#000', fontSize: 14 }}}>
                                    {brands.map(brand => (
                                        <ListItem style={{width: 130}}>
                                            <a href={`/#/all-watches/${brand['_id']}`} className="arrival-link">{brand['title']}</a>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                        <Box>
                        All products
                        </Box>
                    </Box>

                    <Slider data={products} />
                </Box>
                
            </Box>

            <Box className="discover" sx={{
                flexGrow: 1,
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                pt: 15,
                background: `#000 url(${process.env.PUBLIC_URL}/images/bg-home-discover.jpg) no-repeat top center`,
                width: '100%',
                backgroundSize: 'cover',
            }}>

                <Box className="inner">
                    <Box sx={{}}>
                        <Box className="discover__header" sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                            },
                            [theme.breakpoints.down("md")]: {
                                flexDirection: 'row',
                                flexWrap: 'wrap'

                            },
                        })}>
                            <Box className="discover__col1">
                                <Box className="discover__sectionTitle">
                                    Our story 
                                </Box>
                            </Box>
                            <Box className="discover__col2">
                                <Box className="discover__sectionTitle">
                                    Royal Boutique
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    

                    <Box className="discover__content" sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        },
                        [theme.breakpoints.down("md")]: {
                            flexDirection: 'column'
                        },
                    })}>

                        <Box className="discover__col1">
                            <Box>
                                <svg className="discover__cog icon icon-cog">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                            </Box>
                        </Box>
                        <Box className="discover__col2" sx={(theme) => ({
                                [theme.breakpoints.down("md")]: {
                                    width: '100%'
                                },
                            })}>
                            <Typography variant="h2" sx={(theme) => ({
                                color: "#FFF",
                                [theme.breakpoints.up("md")]: {
                                    fontSize: '52px',
                                    lineHeight: '66px',
                                },
                                [theme.breakpoints.down("md")]: {
                                    fontSize: '32px',
                                    lineHeight: '46px',
                                    width: '100%'
                                },
                            })}>
                                We are a team of watch enthusiasts who puts its passion and professionalism at your disposal to offer you the latest collections and the best service. 
                            </Typography>

                            <Box className="discover__text">
                                We are delighted to provide you with an ultimate customer experience in our showroom located in the marvelous Opus tower in Dubai, designed by the award-winning architect Zaha Hadid. The elegancy of the interior design full of harmony  and discretion reflects our professional expertise: excellence, precision and attention to details. 
                            </Box>

                            <Box onClick={() => goTo("/our-showroom")}>
                                <a href="javascript:void(0);">Learn more</a>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box onClick={() => goTo("/our-showroom")} style={{position: 'relative'}} sx={(theme) => ({
                        [theme.breakpoints.down("md")]: {
                            height: '85vh',
                        },
                    })}>
                    <Box className="discover__discoverTitle" sx={(theme) => ({
                        [theme.breakpoints.down("md")]: {
                            top: '48%',
                            width: '100%',
                            padding: '0 20px',
                            fontSize: '90px',
                            lineHeight: '100px',
                            textAlign: 'center',
                            right: 0,
                            transform: 'translate(0, -50%)',
                        },
                    })}>
                        <Typography variant="h1" sx={(theme) => ({
                            [theme.breakpoints.down("md")]: {
                                fontSize: '48px',
                                lineHeight: '50px',
                            },
                            [theme.breakpoints.up("md")]:{
                                fontSize: '120px',
                                lineHeight: '128px',
                                fontWeight: '300',
                            }
                        })}>
                            Discover our
                            Showroom
                        </Typography>
                    </Box>
                    

                    <Box className="discover__section--anim">
                        <Box className="discoverTranslate" sx={(theme) => ({
                            [theme.breakpoints.down("md")]: {
                                left: 'auto',
                                height: '85vh',
                            }})}>
                            <Box className="discoverMixBlend">
                                <Poylon />
                                <div className="discoverbg" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/BET_4844_blacked30.jpg)`}}></div>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>


            <Box 
                sx={{
                    pt: 10,
                    pb: 20,
                }}
                className="serviceShowcase"
            >
                <Box className="inner">
                    <Typography variant="h2" sx={(theme) => ({
                        
                        [theme.breakpoints.up("md")]: {
                            width: "660px",
                            mb: 10,
                        },
                        [theme.breakpoints.down("md")]: {
                            fontSize: '32px',
                            lineHeight: '42px',
                            mb: 0,
                        },
                    })}>
                        Royal Boutique distinguishes itself by its quality and service recognized worldwide.
                    </Typography>

                    <Service 
                        title={'Buy'}
                        image={`${process.env.PUBLIC_URL}/images/icone_buy.svg`} 
                        text={'Royal Boutique offers the most exclusive and desirable watches on the market. We sell all our watches complete with box and paper, and you’ll also get 1-year warranty when you purchase your watch from us.'}
                    />
                    <Service 
                        title={'Requests'}
                        image={`${process.env.PUBLIC_URL}/images/icone_request.svg`} 
                        text={'If you can’t find your dream watch in stock – don’t worry! Royal Boutique gets new arrivals every week so it’s most likely that we’ll receive the watch you’re looking for very soon.'}
                    />
                    <Service 
                        image={`${process.env.PUBLIC_URL}/images/icone_service-repair.png`} 
                        title={'Service & Repair'}
                        text={'Royal Boutique is at your service when your watch needs a repair. Our watch makers are using the latest techniques to repair, service and polish your watch.'}
                    />
                </Box>
            </Box>

            <Box 
                sx={{
                    pt: 10,
                    pb: 20,
                    width: '100vw',
                    height: '100vh',
                    backgroundImage: `url(${imageUrl})`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: "#FFF",
                }}
                className={'sliderBrands'}
            >
                <Box className="slideBrands__zoomImg" style={{backgroundImage: 'inherit'}}></Box>
                <Box className="slideBrands__content">
                    <Typography variant="h5" sx={{p: 2}}>Our brands</Typography>
                    <Typography variant="h3">Audemars Piguet</Typography>
                    <Typography variant="h5" sx={{p: 2}}>All watches</Typography>
                </Box>
                <Box className="sliderBrands__controls">
                    <Clock 
                        onRightClick={()=> setImageUrl(images[0])} 
                        onLeftClick={()=> setImageUrl(images[1])}
                        className={'show-clock'}
                        timer={2}
                    />
                </Box>
            </Box>


            <Footer />
            
        </Box>
    );
};

export default Home;