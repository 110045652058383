import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SlideItem from "../SlideItem/SlideItem";
import './Slider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

type Props = {
  data: any[]
};

const baseURL = process.env.REACT_APP_API;
const UPLOAD_URL = baseURL + "uploads";

const SliderWrap = (props: Props) => {

  const [ left, setLeft ] = useState("");
  const [ isHidden, setIsHidden ] = useState(true);
  const [ data, setData ] = useState(props.data);
  const navigate = useNavigate();

  const navigateToDetails = (id: number) => {
    setTimeout(() => {
        navigate(`/watch/${id}`);
    }, 0)
  }

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {

    const handleScroll = () => {
      if(window.scrollY > (window.innerHeight / 2)){
        setIsHidden(false)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box style={{margin: '0 -25px'}}>
      <Swiper
        spaceBetween={50}
        slidesPerView={3}
        onSlideChange={(e) => {
          const calc = ( (e.activeIndex) / (data.length - 1) ) * 100;
          if(calc < 80){
            setLeft(calc + "%");
          }
        }}
        breakpoints={{
          0: {
            slidesPerView: 1.2,
            spaceBetween: 10
          },
          576: {
            slidesPerView: 2.2,
            spaceBetween: 10
          },
          1024: {
            slidesPerView: 3.2,
            spaceBetween: 50
          },
        }}
        className={isHidden ? 'swiper-hidden' : 'swiper-show'}
      >
        {data.map((item) => (
          <SwiperSlide>
            <SlideItem
              title={item.title}
              description={item.description}
              onClick={() => navigateToDetails(item._id)} 
              img={`${UPLOAD_URL}/${item.image}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box className="watchSlider__scrollBar">
        <Box className="watchSlider__scrollBar-pan" style={{width: 'calc(37.5%)', left}}></Box>
      </Box>
    </Box>
  );
};

export default SliderWrap;
