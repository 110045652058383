import { CssBaseline, ThemeProvider } from "@mui/material";
import { ClientContext } from "./context/ClientProvider";
import axios from "axios";
import { theme } from "./theme";
import "./App.css";
import { Route, BrowserRouter as Router, Routes, HashRouter } from "react-router-dom";
import { routes } from "./routes";
import { Loader } from "./components/Loader";
import { LoaderProvider } from "./context/LoaderProvider";

const baseURL = process.env.REACT_APP_API;
const api = axios.create({
    baseURL: baseURL,
    withCredentials: true
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LoaderProvider>
        <ClientContext.Provider value={api}>
          <CssBaseline />
          <HashRouter>
              <Routes>
                {routes.map((route) => (
                  <Route
                    key={route.key}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
              </Routes>
          </HashRouter>
          <Loader />
        </ClientContext.Provider>
      </LoaderProvider>
    </ThemeProvider>
  );
}

export default App;