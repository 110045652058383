import React, { FC, useEffect, useState } from "react";
import {Box, List, ListItem, Typography} from "@mui/material";
import './Watch.css';
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
import { Slider } from "../components/Slider";
import { useParams } from "react-router-dom";
import { useApiClient } from "../context/ClientProvider";

const baseURL = process.env.REACT_APP_API;
const UPLOAD_URL = baseURL + "uploads";

const Watch: FC<any> = (): JSX.Element => {

    const { id } = useParams();
    const [brands, setBrands] = useState([]);
    const [ products, setProducts ]  = useState<any[]>([]);
    const [ watch, setWatch ]  = useState<any>();
    const apiClient = useApiClient();

    useEffect(() => {
        
        const getBrands = async () => {
            const brands = await apiClient.get(`api/categories?limit=10&size=0`);
            setBrands(brands.data.data || []);
        };

        getBrands();
    }, []);

    useEffect(() => {
        
        const getProducts = async () => {
            const api = await apiClient.get(`api/watches?limit=20&size=0`);

            setProducts(api.data?.data);
        };

        const getWatch = async () => {
            const api = await apiClient.get(`api/watch?id=${id}`);

            setWatch(api.data);
        };

        getProducts();
        getWatch();
    }, []);

    return (
        <Box className="watch">
            <Box sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'hidden',
                    background: '#FFF',
                    position: 'relative',
                },
                [theme.breakpoints.down("md")]: {
                    display: 'block',
                    height: 'auto',
                },
            })}>

                <Navbar class='header' dark />

                <Box className='inner' sx={(theme) => ({
                    [theme.breakpoints.down("md")]: {
                        display: 'block',
                        padding: 0
                    },
                })}>
                    <Box 
                        className="watchSheet__gallery-image" 
                        style={{backgroundImage: `url(${UPLOAD_URL}/${watch?.image})`}}
                        sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                width: '50%',
                            },
                            [theme.breakpoints.down("md")]: {
                                width: '100%',
                            },
                        })}
                    />

                    <Box sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            display: 'flex',justifyContent: 'flex-end'
                        },
                        [theme.breakpoints.down("md")]: {
                            display: 'block',
                        },
                    })}>
                        <Box className="watchSheet__content">
                            <Box className="watchSheet__heading">
                                <p className="watchSheet__brand">{watch?.title}</p>
                                <p className="watchSheet__title">{watch?.description}</p>
                            </Box>
                            <Box className="watchSheet__infos">
                                <Box className="watchSheet__item">
                                    <Box className="watchSheet__item-label">Reference</Box>
                                    <Box className="watchSheet__item-value">{watch?.reference}</Box>
                                </Box>
                                <Box className="watchSheet__item">
                                    <Box className="watchSheet__item-label">Brand</Box>
                                    <Box className="watchSheet__item-value">{watch?.title}</Box>
                                </Box>
                                <Box className="watchSheet__item">
                                    <Box className="watchSheet__item-label">Model</Box>
                                    <Box className="watchSheet__item-value">{watch?.model}</Box>
                                </Box>
                                <Box className="watchSheet__item">
                                    <Box className="watchSheet__item-label">Movement</Box>
                                    <Box className="watchSheet__item-value">{watch?.movement}</Box>
                                </Box>
                                <Box className="watchSheet__item">
                                    <Box className="watchSheet__item-label">Year of manufacture</Box>
                                    <Box className="watchSheet__item-value">{watch?.year}</Box>
                                </Box>
                                <Box className="watchSheet__item">
                                    <Box className="watchSheet__item-label">Sex</Box>
                                    <Box className="watchSheet__item-value">{watch?.sex}</Box>
                                </Box>
                                <Box className="watchSheet__item">
                                    <Box className="watchSheet__item-label">Wristband material</Box>
                                    <Box className="watchSheet__item-value">{watch?.material}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </Box>

            <Box 
                sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        display: 'flex',
                        position: 'relative',
                        overflow: 'hidden',
                        paddingTop: '180px',
                        paddingBottom: '150px',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                    },
                    [theme.breakpoints.down("md")]: {
                        display: 'block',
                    },
                })}
                className='inner'
            >
                <Box className="watchSheet" sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        width: '50%'
                    },
                    [theme.breakpoints.down("md")]: {
                        width: '100%',
                        mt: 20,
                    },
                })}>
                    <img src={`${UPLOAD_URL}/${watch?.image}`} alt="" height={'100%'} />
                </Box>
                <Box  sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        width: '50%', marginTop: 10
                    },
                    [theme.breakpoints.down("md")]: {
                        width: '100%', marginTop: 3, marginBottom: 20
                    },
                })}>
                    <Typography className="tiTwoCols__text">
                        {watch?.info}
                    </Typography>
                </Box>
            </Box>


            <Box className="contactPush" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/Bitmap-1.png)`}}>
                <Typography variant="h1" className="contactPush__title title title-1" sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        fontSize: '120px',
                        lineHeight: '128px',
                    },
                    [theme.breakpoints.down("md")]: {
                        fontSize: '48px',
                    },
                })}>
                    Interested ?<br />
                    Contact us
                </Typography>
            </Box>



            <Box sx={{
                flexGrow: 1,
                background: '#FFF',
                position: 'relative',
                overflow: 'hidden',
                pt: 15,
                mb: 20,
            }}>
                <Box className="inner">
                    <Box sx={{mb: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Box sx={{display: 'flex', flexGrow: 1}}>
                            <Typography variant="h4">Latest Arrivals</Typography>
                            <Box>
                                <List sx={{display: { xs: "none", md: "flex", justifyContent: "center", alignItems: 'center', width: '100%', color: '#000', fontSize: 14 }}}>
                                    {brands.map(brand => (
                                        <ListItem style={{width: 130}}>
                                            <a href={`/#/all-watches/${brand['_id']}`} className="arrival-link">{brand['title']}</a>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                        <Box>
                        All products
                        </Box>
                    </Box>


                    <Slider data={products} />
                    
                    <Box sx={{display: 'flex',flexDirection: 'row',margin: '0 -25px'}}>
                        
                    </Box>
                </Box>
                
            </Box>


            <Footer />
            
        </Box>
    );
};

export default Watch;