import * as React from 'react';
import { useEffect } from 'react';

type Props = {
  url: string,
  className: string,
  soundOff?: boolean,
};

const Video: React.FC<Props> = (props: Props): JSX.Element => {
  const { url, className, soundOff } = props;

  useEffect(() => {
    const video = document.getElementById("video") as HTMLVideoElement;
    video.muted = soundOff || false;
  }, [soundOff, url]);

  return (
    <video key={url} className={className} id="video" preload="auto" muted autoPlay={true} loop playsInline>
        <source src={url} type="video/mp4" />
    </video>
  );
}

export default Video;