import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {ReactComponent as LoadIcon} from "../../images/loader.svg";
import './Loader.css';
import * as d3 from "d3";
import { LoaderContext } from "../../context/LoaderProvider";

type Props = {
  
};

const Loader = (props: Props) => {

  const {load, setLoad} = useContext(LoaderContext);

  useEffect(() => {

    if(!load){
      return;
    }

    const loaderPage = document.getElementsByClassName("js-loader-page")[0];
    const pinkwave_groupe = document.getElementById("pinkwave_groupe");
    const whitewave_group = document.getElementById("whitewave_group");

    const finalPinkPoints = document.getElementsByClassName("js-pinkwave_final")[0].getAttribute('points') || "";
    const startPinkPoints = document.getElementsByClassName("js-pinkwave_start")[0].getAttribute('points') || "";

    const reset = () => {
      document.getElementsByClassName("js-pinkwave_start")[0].setAttribute('points', startPinkPoints);
      pinkwave_groupe?.classList.remove('loader-translate');
      whitewave_group?.classList.remove('loader-translate-white');
      loaderPage.classList.remove('active');
    }
    reset();
    
    loaderPage.classList.add('class', 'active');
    pinkwave_groupe?.classList.add('class', 'loader-translate');
    whitewave_group?.classList.add('class', 'loader-translate-white');
    loaderPage.classList.add('class', 'loader-translate-up');

    const polygon = d3.select(document.getElementsByClassName('js-pinkwave_start')[0]);
    polygon
      .transition()
      .duration(1000)
      .attr('fill', '#D7BBA3')
      .attr('points', finalPinkPoints);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
    
    setTimeout(() => {
      reset();
      setLoad(false);
    }, 2700)
  }, [load]);

  return (
    <Box className="loaderPage js-loader-page">
        <Box className="loaderPage__wave js-loaderPage-wave">
            
          <LoadIcon />
        </Box>
    </Box>
  );
};

export default Loader;
