import { FC } from "react";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Showroom from "./pages/Showroom";
import VipArea from "./pages/VipArea";
import Watch from "./pages/Watch";
import Watches from "./pages/Watches";

export interface Route {
    key: string,
    title: string,
    path: string,
    navItem: boolean,
    component: FC<{}>,
}

export const routes: Array<Route> = [
    {
        key: 'home',
        title: 'Home',
        path: '/',
        navItem: true,
        component: Home,
    },
    {
        key: 'watches',
        title: 'All Watches',
        path: '/all-watches',
        navItem: true,
        component: Watches,
    },
    {
        key: 'watchesByBrand',
        title: 'Watches by brand',
        path: '/all-watches/:id/',
        navItem: true,
        component: Watches,
    },
    {
        key: 'watch',
        title: 'Watch',
        path: '/watch/:id/',
        navItem: true,
        component: Watch,
    },
    {
        key: 'showroom',
        title: 'Our Showroom',
        path: '/our-showroom',
        navItem: true,
        component: Showroom,
    },
    {
        key: 'viparea',
        title: 'VIP Area',
        path: '/vip-area',
        navItem: true,
        component: VipArea,
    },
]

export const NotFoundRoute = {
    key: 'not-found-route',
    title: 'Not Found',
    path: '*',
    navItem: true,
    component: NotFound
}