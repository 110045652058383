import React, { FC } from "react";
import {Box, Typography} from "@mui/material";
import './Showroom.css';
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

const Showroom: FC<any> = (): JSX.Element => {

    return (
        <Box className="showroom">
            <Box sx={{
                height: '100vh',
                overflow: 'hidden',
                position: 'relative',
            }} className="pageHero" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/BET_4844_blacked30-1920x920.jpg)`}}>

                <Navbar class='header' />

                <Typography variant="h1" className="pageHero__title title title-1" sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        fontSize: '120px',
                        lineHeight: '128px',
                    },
                    [theme.breakpoints.down("md")]: {
                        fontSize: '48px',
                        lineHeight: '50px',
                    },
                })}>
                    Our story &<br />
                    commitments
                </Typography>

                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                   
                </Box>

            </Box>

            <Box 
                className='inner' 
                sx={{
                    display: 'flex',
                    position: 'relative',
                    overflow: 'hidden',
                    paddingBottom: '150px',
                    flexWrap: 'wrap',
                }}
            >
                    <Box className="tiTwoCols__wrapper">
                        <Box className="tiTwoCols__col1">
                            <img src={`${process.env.PUBLIC_URL}/images/BET_4592_showroom_01.jpg`} alt="" width={'100%'} />
                        </Box>
                        <Box className="tiTwoCols__col1" sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                width: '30%'
                            },
                            [theme.breakpoints.down("md")]: {
                                width: '100%',
                                mt: 5
                            },
                        })}>
                            <Typography variant="h5">About the boutique</Typography>
                            <p className="tiTwoCols__text">
                                We offer you the most exclusive and desirable timepieces in the world from an incredible selection of Rolex, Audemars Piguet, Patek Philippe & Richard Mille.
                            </p>
                        </Box>
                        <Box className="tiTwoCols__col1" sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                width: '30%'
                            },
                            [theme.breakpoints.down("md")]: {
                                width: '100%'
                            },
                        })}>
                            <p className="tiTwoCols__text">We are delighted to provide you with an ultimate customer experience in our showroom located in the marvelous Opus tower in Dubai, designed by the award-winning architect Zaha Hadid. The elegancy of the interior design full of harmony and discretion reflects our professional expertise: excellence, precision and prudence.</p><br />
                            <p style={{fontWeight: 'bold'}}>We buy all types of luxury watches including Audemars Piguet, Patek Phlippe, Richard Mille and Rolex. We also help you get the best if you're looking to purchase any of the brands mentioned.</p>
                        </Box>
                        <Box className="tiTwoCols__col1" sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                marginTop: '-230px', textAlign: 'right'
                            },
                            [theme.breakpoints.down("md")]: {
                                marginTop: 0,
                                width: '100%',
                                margin: '0 auto',
                            },
                        })}>
                            <img src={`${process.env.PUBLIC_URL}/images/BET_4592_showroom_02-355x592.jpg`} alt="" width={'100%'} />
                        </Box>
                    </Box>
            </Box>


            <Box sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    display: 'flex',
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100vh',
                    alignItems: 'center',
                },
                [theme.breakpoints.down("md")]: {
                    display: 'block'
                },
            })}>
                <Box 
                    className="pageHero" 
                    style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bloc2.jpg)` }}
                    sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            width: '50vw',
                            height: '100vh',
                        },
                        [theme.breakpoints.down("md")]: {
                            width: '100vw',
                            height: '80vh',
                        },
                    })}
                >
                    
                </Box>
                <Box sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        width: '50%', marginTop: '55px'
                    },
                    [theme.breakpoints.down("md")]: {
                        width: '100%', marginTop: 0
                    },
                })}>
                    <Box sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            width: '50%',
                            ml: 10
                        },
                        [theme.breakpoints.down("md")]: {
                            width: '100%',
                            p: 2,
                            pt: 10,
                            pb: 10
                        },
                    })}>
                        <Typography variant="h3" sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                fontSize: '52px',
                                lineHeight: '66px',
                            },
                            [theme.breakpoints.down("md")]: {
                                fontSize: '32px',
                                lineHeight: '46px',
                                width: '100%'
                            },
                        })}>
                            Our team looks forward to welcome you
                        </Typography>
                        <p className="textImage__text-text">
                            We are a team of watch enthusiasts who puts its passion and professionalism at your disposal to offer you the latest collections and the best service. We help our customers to choose the watch of their dreams with personalized and private viewings in our showroom.
                        </p>
                    </Box>
                </Box>
            </Box>


            <Box className="contactPush" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/BET_5020_push-bas-page_blacked30-1920x920.jpg)`}}>
                <Typography variant="h1" className="contactPush__title title title-1" sx={(theme) => ({
                    [theme.breakpoints.up("md")]: {
                        fontSize: '120px',
                        lineHeight: '128px',
                    },
                    [theme.breakpoints.down("md")]: {
                        fontSize: '48px',
                        lineHeight: '50px',
                    },
                })}>
                    Contact us<br />
                    for further details
                </Typography>
            </Box>


            <Footer />
            
        </Box>
    );
};

export default Showroom;