import { FC, useContext, useEffect, useState } from "react";
import { Box, Container, Grid, List, ListItem, Typography } from "@mui/material";
import './Footer.css'
import { useNavigate } from "react-router-dom";
import { LoaderContext } from "../../context/LoaderProvider";

type Props = {
  
}

export const Footer: FC<Props> = (props: Props): JSX.Element => {

    const [ isHidden, setIsHidden ] = useState(true);
    const navigate = useNavigate();
    const {setLoad} = useContext(LoaderContext);

    let ui = {
        countaa: 0,
        footerScroll: 0,
        lastTouch: {
            y: 0,
            time: 0
        },
        lastScrolls: [0,0]
    }

    const goTo = (link: string) => {
        setLoad(true);

        setTimeout(() => {
            navigate(link);
        }, 1000);
    }

    useEffect(() => {

        // const footerElement = document.getElementById('footer') as HTMLElement;
        // if(!footerElement) return;

        // const handleScroll = (e: any) => {
        //     if (ui.footerScroll > 0) {
        //         e.preventDefault()
        //         e.stopPropagation()
        //         if ((window.innerHeight + window.scrollY + 100) < document.body.offsetHeight) {
        //             ui.footerScroll = 0;
        //             footerElement.setAttribute('style', 'transform: translateY(0)');
        //         }
        //     } else {
        //         ui.footerScroll = 0
        //     }
        // };

        // function outerHeight(elem: any){
        //     var curStyle = elem.currentStyle || window.getComputedStyle(elem);
        //     let outerHeight = elem.offsetHeight;
        //     outerHeight += parseInt(curStyle.marginTop);
        //     outerHeight += parseInt(curStyle.marginBottom);
            
        //     return outerHeight;
        // }

        // const onMouseWheel = (e: any) => {
        //     if (ui.footerScroll > 0) {
        //         e.preventDefault()
        //         e.stopPropagation()
        //         if (((window.innerHeight + window.scrollY) < document.body.offsetHeight) || e.defaultPrevented == false) {
        //             window.scrollTo(0, document.body.offsetHeight)
        //         }
        //     } else {
        //         ui.footerScroll = 0
        //     }
        //     let scroll = 0;
        //     if (e.wheelDeltaY) {
        //         scroll = e.deltaY;
        //     } else {
        //         scroll = Math.sign(e.deltaY) * 50;
        //     }
        //     if (scroll > 0) {
        //         if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        //             ui.footerScroll += scroll
        //         }
        //     } else {
        //         ui.footerScroll += scroll
        //     }
        //     if (ui.footerScroll > 0) {
        //         if (ui.footerScroll > outerHeight(footerElement)) {
        //             ui.footerScroll = Math.floor(outerHeight(footerElement));
        //         }
        //         footerElement.setAttribute('style', 'transform: translateY(-' + ui.footerScroll + 'px)');
        //     } else {
        //         footerElement.setAttribute('style', 'transform: translateY(0)');
        //     }
        // }

        // window.addEventListener('scroll', handleScroll, {passive: false});
        // window.addEventListener('wheel', onMouseWheel, {passive: false});

        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        //     window.removeEventListener('wheel', handleScroll);
        // };
    }, [])

  return (
    <Box id="footer" sx={{
      display: 'flex',
      justifyContent: 'center', 
      alignItems: 'center',
      background: '#FFF',
    }}>

        <Box
            sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    width: '70%',
                    height: '100%',
                    mt: 10
                },
                [theme.breakpoints.down("md")]: {
                    width: '90%',
                },
            })}
        >
            <Box sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    width: '100%', mb: 15, ml: -5
                },
                [theme.breakpoints.down("md")]: {
                    mb: 5,
                    mt: 5,
                    height: 50,
                },
            })}>
                <img src={`${process.env.PUBLIC_URL}/images/logo-black.svg`} alt="" className="" height={'100%'} />
            </Box>
            <Box sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    display: 'flex', justifyContent: 'center', flexDirection: 'row', width: '100%',
                },
                [theme.breakpoints.down("md")]: {
                    display: 'block',
                },
            })}>

                <Box sx={{flex: 1}}>
                    <Typography variant="h5" sx={{flex: 1, fontSize: '14px', lineHeight: '23px', opacity: '0.3', color: '#000000', width: 200, mb: 5}}>
                        We are a team of watch enthusiasts that everyday has the privilege to work with our biggest interest.
                    </Typography>
                </Box>

                <Box sx={{flex: 1, }}>
                    <Box sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            width: 300, paddingTop: '25px', borderTop: '1px solid #000',
                        },
                        [theme.breakpoints.down("md")]: {
                            width: '100%', paddingTop: '25px', borderTop: '1px solid #000',
                        },
                    })}>
                        <Typography>Menu</Typography>
                        <List>
                            <ListItem onClick={() => goTo("/all-watches")}>
                                <a href="javascript:void(0);" className="footer-link" >Watches</a>
                            </ListItem>
                            <ListItem onClick={() => goTo("/our-showroom")}>
                                <a href="javascript:void(0);" className="footer-link">Our showroom</a>
                            </ListItem>
                            <ListItem onClick={() => goTo("/vip-area")}>
                                <a href="javascript:void(0);" className="footer-link">VIP Area</a>
                            </ListItem>
                            <ListItem>
                                <a href="javascript:void(0);" className="footer-link">Services</a>
                            </ListItem>
                            <ListItem>
                                <a href="javascript:void(0);" className="footer-link">Contact</a>
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                <Box sx={{flex: 1, paddingTop: '25px',borderTop: '1px solid #000'}}>

                    <List sx={{flex: 1}}>
                        <ListItem style={{display: 'flex', flexDirection: 'column',justifyContent: 'flex-start', width: '100%', textAlign: 'left', padding: 0 }}>
                            <Typography variant="h5" style={{width: '100%', marginBottom: 28,fontSize: '14px',lineHeight: '16px'}}>Royal Boutique Address</Typography>
                            <Box style={{width: '100%', opacity: '0.3', color: '#000000', fontSize: '14px', lineHeight: '23px'}}>
                                Opus Builiding<br />
                                Al Aamal Street Business Bay<br />
                                Khalifa district<br />
                                Dubai - United Arab Emirates<br /><br />

                                T. +971 4 511 5000<br />
                                contact@royalboutique.ae<br />
                            </Box>    
                        </ListItem>
                        

                        <Box sx={(theme) => ({
                            [theme.breakpoints.up("md")]: {
                                flex: 1, paddingTop: 10,borderTop: '1px solid #000',marginTop: 10
                            },
                            [theme.breakpoints.down("md")]: {
                                flex: 1, paddingTop: 2,borderTop: '1px solid #000',marginTop: 10,marginBottom: 2
                            },
                        })}>
                            <ListItem style={{padding: 0, marginBottom: 20 }}>Subscribe to our Newsletter</ListItem>

                            <input name="input_1" id="input_4_1" className="form-input" type="text" value="" placeholder="Your email address" aria-required="true" aria-invalid="false" />
                        </Box>

                    </List>

                </Box>
            </Box>

            <Box sx={(theme) => ({
                [theme.breakpoints.up("md")]: {
                    display: 'flex', flexDirection: 'row', width: '100%',fontSize: 14,fontWeight: 400,lineHeight: 20
                },
                [theme.breakpoints.down("md")]: {
                    display: 'block',fontSize: '12px', mb: 2
                },
            })}>
                <Box className="footer-copyright" sx={{flex: 1 }}>
                    © 2020 - Royal Boutique
                </Box>
                {/* <Box className="footer-copyright" sx={{flex: 1 }}>
                    © 2020 - Royal Boutique
                </Box>
                <Box className="footer-copyright" sx={{flex: 1 }}>
                    © 2020 - Royal Boutique
                </Box> */}
            </Box>
        </Box>
        
    </Box>
  );
};

export default Footer;